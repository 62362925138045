import HelpCenterApi from '@/api/HelpCenter'

export default {

    async store(form) {

        return await HelpCenterApi.post('api/contact', form)
            .then(() => {

                return true

            }).catch(() => {

                return false

            })

    }

}