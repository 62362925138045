import AuthApi from '@/api/Auth'

export default {

    async update(preference, form) {

        return await AuthApi.put(`api/preference/${preference.id}`, form)
            .then(() => {

                return true

            }).catch(() => {

                return false

            })

    }

}