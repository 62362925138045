export const objectKeysConversion = {

    methods: {

        camelToUnderscore(key) {

            return key.replace( /([A-Z])/g, "_$1").toLowerCase();

        },

        toCamel(s) {

          return s.replace(/([-_][a-z])/ig, ($1) => {

            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '')

          })

        },

        isArray(a) {

            return Array.isArray(a)

        },

        isObject(o) {

            return o === Object(o) && ! this.isArray(o) && typeof o !== 'function'

        },

        keysToCamel(o) {

          if (this.isObject(o)) {

            const n = {}

            Object.keys(o)
                .forEach((k) => {

                  n[this.toCamel(k)] = this.keysToCamel(o[k])

                })

            return n

          } else if (this.isArray(o)) {

            return o.map((i) => {

              return this.keysToCamel(i)

            })

          }

          return o

        },

        keysToSnack(o) {

            if (this.isObject(o)) {

                const n = {}

                Object.keys(o)
                    .forEach((k) => {

                        n[this.camelToUnderscore(k)] = this.keysToSnack(o[k])

                    })

                return n

            } else if (this.isArray(o)) {

                return o.map((i) => {

                    return this.keysToSnack(i)

                })

            }

            return o

        }

    }

}