<template>

  <ul class="nk-sticky-toolbar">
    <li class="demo-layout">
      <a class="toggle tipinfo" data-target="demoML" href="#" title="Main Demo Preview">
        <em class="icon ni ni-menu-squared"></em>
      </a>
    </li>
    <li class="demo-thumb">
      <a class="tipinfo" :href="`${HELP_CENTER_APP}/privacy-policy`" target="_blank" title="Use Case Concept">
        <em class="icon ni ni-policy"></em>
      </a>
    </li>
    <li class="demo-purchase">
      <a class="tipinfo" :href="`${HELP_CENTER_APP}/contact`" target="_blank" title="Purchase">
        <em class="icon ni ni-contact"></em>
      </a>
    </li>
  </ul>

</template>

<script>

export default {

  name: 'Toolbar',

  data(){
    return {

      HELP_CENTER_APP: process.env.VUE_APP_HELP_CENTER_APP

    }
  },

}

</script>