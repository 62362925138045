<template>

  <button :class="buttonClass" :disabled="disabled || processing">

    <em v-if="icon && iconPosition === 'left' && ! processing" :class="icon"></em>

    <span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

    <span v-if="processing">{{ $t(processingText) }}</span>

    <span v-if="! processing">{{ $t(text) }}</span>

    <em v-if="icon && iconPosition === 'right' && ! processing" :class="icon"></em>

  </button>

</template>

<script>

  export default {

    name: 'submit-button',

    props: {

      icon: {
        type: String,
        default: null
      },

      iconPosition: {
        type: String,
        default: 'left'
      },

      text: {
        type: String
      },

      buttonClass: {
        type: String,
        default: 'btn btn-primary'
      },

      processingText: {
        type: String,
        default: 'Being processed...'
      },

      disabled: {
        type: Boolean,
        default: false
      },

      processing: {
        type: Boolean,
        default: false
      }

    }

  }

</script>