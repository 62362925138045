import Vue         from 'vue'
import Vuex        from 'vuex'
import sessionData from '@/store/modules/sessionData'

Vue.use(Vuex)

export default new Vuex.Store({

    modules: {
        sessionData
    }

})