import {
    extend,
    setInteractionMode
} from 'vee-validate'

import {
    min,
    max,
    size,
    email,
    image,
    regex,
    length,
    numeric,
    required,
    min_value,
    max_value,
    confirmed
} from 'vee-validate/dist/rules'

extend('min',       min)
extend('max',       max)
extend('size',      size)
extend('image',     image)
extend('regex',     regex)
extend('email',     email)
extend('length',    length)
extend('numeric',   numeric)
extend('required',  required)
extend('confirmed', confirmed)
extend('min_value', min_value)
extend('max_value', max_value)

extend('phoneNumber', {

    validate(value, { valid }) {

        return valid === 'true'

    },

    params: ['valid'],

})

extend('orangePhoneNumber', {

    validate(value) {

        return /^(77|78)[1-9]{7}$/.test(value.replaceAll(' ', ''))

    }

})

setInteractionMode('eager')