import Vue           from 'vue'
import VueI18n       from 'vue-i18n'
import VueCookies    from 'vue-cookies'
import { configure } from 'vee-validate'

Vue.use(VueI18n)
Vue.use(VueCookies)

function loadLocaleMessages () {

  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  const messages = {}

  locales.keys().forEach(key => {

    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {

      const locale = matched[1]

      messages[locale] = locales(key)

    }

  })

  return messages

}

let defaultLanguageCode = Vue.$cookies.get('locale')
    ? Vue.$cookies.get('locale')
    : (process.env.VUE_APP_I18N_LOCALE ? process.env.VUE_APP_I18N_LOCALE : 'fr')

const VueI18nInstance = new VueI18n({

  locale: defaultLanguageCode,

  fallbackLocale: defaultLanguageCode,

  messages: loadLocaleMessages()

})

configure({

  defaultMessage: (field, values) => {

    values._field_ = VueI18nInstance.t(`validations.fields.${field}`)

    return VueI18nInstance.t(`validations.messages.${values._rule_}`, values)

  }

})

export default VueI18nInstance
