<template>

  <footer class="footer bg-indigo is-dark bg-lighter" id="footer">
    <div class="container">
      <div class="row g-3 align-items-center justify-content-md-between py-4 py-md-5">
        <div class="col-md-3">
          <div class="footer-logo">
            <a href="index.html" class="logo-link">
              <img class="logo-light logo-img" src="@/assets/images/logo.svg" srcset="../../assets/images/logo2x.svg 2x" alt="logo" width="160" height="100">
              <img class="logo-dark logo-img" src="@/assets/images/logo-dark.svg" srcset="../../assets/images/logo-dark2x.svg 2x" alt="logo-dark" width="160" height="100">
            </a>
          </div>
        </div>
        <div class="col-md-9 d-flex justify-content-md-end">
          <ul class="link-inline gx-4"><li><a href="#reviews">{{ $t('Why us') }} ?</a></li>
            <li><a :href="`${HELP_CENTER_APP}/contact`" target="_blank">{{ $t('Help') }}</a></li>
            <li><a :href="`${HELP_CENTER_APP}/pricing`" target="_blank">{{ $t('Pricing') }}</a></li>
            <li><a :href="`${HELP_CENTER_APP}/contact`" target="_blank">{{ $t('Contact') }}</a></li>
          </ul>
        </div>
      </div>
      <hr class="hr border-light mb-0 mt-n1">
      <div class="row g-3 align-items-center justify-content-md-between py-4">
        <div class="col-md-8">
          <div class="text-base">{{ $t('Copyright') }} &copy; 2022 Samapayor. {{ $t('All Rights Reserved') }}</div>
        </div>
        <div class="col-md-4 d-flex justify-content-md-end">
          <ul class="social">
            <li><a href="#"><em class="icon ni ni-twitter"></em></a></li>
            <li><a href="#"><em class="icon ni ni-facebook-f"></em></a></li>
            <li><a href="#"><em class="icon ni ni-instagram"></em></a></li>
            <li><a href="#"><em class="icon ni ni-pinterest"></em></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>

export default {

  name: 'Footer',

  data(){
    return {

      HELP_CENTER_APP: process.env.VUE_APP_HELP_CENTER_APP

    }
  },

}

</script>