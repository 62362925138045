<template>

  <div class="nk-demo-panel toggle-slide toggle-slide-right" data-content="demoML" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
    <div class="nk-demo-head">
      <h6 class="mb-0">Apps</h6>
      <a class="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2" data-target="demoML" href="index.html#">
        <em class="icon ni ni-cross"></em>
      </a>
    </div>
    <div class="nk-demo-list" data-simplebar>
      <div class="nk-demo-item">
        <a :href="DEUKOUWAY_APP_DOMAIN" target="_blank">
          <div class="nk-demo-image bg-blue">
            <img class="bg-purple" src="@/assets/images/landing/layout-1d.jpg" srcset="../../assets/images/landing/layout-1d2x.jpg 2x" alt="Demo Layout 1">
          </div>
          <span class="nk-demo-title">
              <strong>Deukouway</strong>
            </span>
        </a>
      </div>
    </div>
  </div>

</template>

<script>

export default {

  name: 'AppPanel',

  data(){
    return {

      DEUKOUWAY_APP_DOMAIN: process.env.VUE_APP_DEUKOUWAY_APP,

    }
  },

}

</script>