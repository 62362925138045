<template>

  <section class="section section-feature pb-0" id="feature">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-5">
          <div class="img-block img-block-s1 left card overflow-hidden bg-white shadow-lg mb-5">
            <img src="@/assets/images/gfx/a.png" alt="Dashlite">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="text-block pe-xl-5">
            <h2 class="title">{{ $t('Simplify the management of your payments') }}</h2>
            <p>{{ $t('A suite of digital products available to companies in several sectors and individuals to facilitate the collection and disbursement of their payments. We have solutions by sector of activity in order to align as much as possible with the needs of the field of activity') }}: <strong>{{ $t('Immovable') }}, {{ $t('Education') }}, {{ $t('Insurance') }}, etc</strong>.</p>
            <ul class="list list-lg list-success list-checked-circle outlined">
              <li>{{ $t('Intuitive screens') }}</li>
              <li>{{ $t('Real-time data') }}</li>
              <li>{{ $t('Accessible to any type of support, 24/7, everywhere') }}</li>
            </ul>
            <ul v-if="!$store.getters['sessionData/currentUser']" class="btns-inline">
              <li><a :href="`${AUTH_APP_DOMAIN}/register`" target="_blank" class="btn btn-lg btn-primary">{{ $t('Getting started') }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

export default {

  name: 'Feature',

  data(){
    return {

      AUTH_APP_DOMAIN: process.env.VUE_APP_AUTH_APP

    }
  },

}

</script>