import store         from '@/store'
import HelpCenterApi from '@/api/HelpCenter'

export default {

    async index() {

        return await HelpCenterApi.get('api/session-data')
            .then(response => {

                return response.data

            }).catch(() => {

                return null

            })

    },

    async loadToVuex() {

        let sessionData = await this.index()

        if (! sessionData)
            return

        store.commit('sessionData/setCurrentUser', sessionData.user)
        store.commit('sessionData/setLanguages',   sessionData.languages)

    },

    async loadAppData() {

        if(! store.getters['sessionData/loaded']) {

            store.commit('sessionData/setLoading', true)

            await this.loadToVuex()

            store.commit('sessionData/setLoading', false)
            store.commit('sessionData/setLoaded', true)

        }

    }

}