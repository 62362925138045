export const vueTelInput = {

    data: () => ({
        phoneNumber: '',
        isPhoneNumberValid: false,
        bindProps: {
            mode: 'national',
            defaultCountry: 'SN',
            disabledFetchingCountry: false,
            disabled: false,
            disabledFormatting: false,
            required: false,
            enabledCountryCode: true,
            enabledFlags: true,
            preferredCountries: ['ML', 'MA'],
            onlyCountries: [],
            ignoredCountries: [],
            autocomplete: 'off',
            name: 'number',
            maxLen: 25,
            wrapperClasses: '',
            inputClasses: '',
            dropdownOptions: {
                disabledDialCode: false
            },
            inputOptions: {
                showDialCode: false
            }
        }
    }),

    computed: {

        isValidPhoneNumber: function () {

            return this.isPhoneNumberValid

        }

    },

}