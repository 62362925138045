export const notification = {

    methods: {

        notifySuccess(title = 'Success', text = 'Your operation is successful.', duration = 5000) {

            this.clearAllNotification()

            this.$notify({
                group: 'notification',
                type: 'success',
                title: this.$t(title),
                text: this.$t(text),
                duration: duration
            });

        },

        notifyError(title = 'Failed', text = 'Your operation is failed.', duration = -1) {

            this.clearAllNotification()

            this.$notify({
                group: 'notification',
                type: 'error',
                title: this.$t(title),
                text: this.$t(text),
                duration: duration
            });

        },

        clearAllNotification() {

            this.$notify({
                group: 'notification',
                clean: true
            });

        }

    }

}