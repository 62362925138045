<template>

  <section class="section section-feature">
    <div class="container">
      <div class="row flex-row-reverse justify-content-between align-items-center">
        <div class="col-lg-5">
          <div class="img-block img-block-s1 right card overflow-hidden bg-white shadow-lg mb-5">
            <img src="@/assets/images/gfx/b.png" alt="Dashlite">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="text-block">
            <h2 class="title">{{ $t('Receive or issue payments instantly') }}</h2>
            <p>{{ $t('An infrastructure of solutions that will allow you to automate your collections and payments') }}. </p>
            <ul class="list list-lg list-success list-checked-circle outlined">
              <li>{{ $t('Rent payments') }}</li>
              <li>{{ $t('Tuition payment') }}</li>
              <li>{{ $t('Salary payments') }}</li>
              <li>{{ $t('Insurance payments') }}</li>
              <li>{{ $t('And others') }}</li>
            </ul>
            <ul v-if="!$store.getters['sessionData/currentUser']" class="btns-inline">
              <li><a :href="`${AUTH_APP_DOMAIN}/register`" target="_blank" class="btn btn-lg btn-primary">{{ $t('Getting started') }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

export default {

  name: 'Feature1',

  data(){
    return {

      AUTH_APP_DOMAIN: process.env.VUE_APP_AUTH_APP

    }
  },

}

</script>