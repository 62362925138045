import { localeChanged } from 'vee-validate'

export const locale = {

    methods: {

        setLocale(locale){

            this.$i18n.locale = locale

            localeChanged()

            this.$cookies.set('locale', locale)


        }

    }

}