<template>

  <li class="menu-item has-sub">
    <a href="#" class="menu-link menu-toggle"><img :src="currentLanguage.flag" alt="" class="language-flag"></a>
    <div class="menu-sub">
      <ul class="menu-list">
        <li v-for="(language, index) in this.languages" :key="index" class="menu-item">
          <a @click.prevent="changeTo(language)" href="#" class="menu-link"><img :src="language.flag" alt="" class="language-flag"> {{ $t(capitalize(language.slug)) }}</a>
        </li>

      </ul>
    </div>
  </li>

</template>

<script>

import {
  locale
} from '@/mixins'

import PreferenceService from '@/services/Preference'

export default {

  name: 'LanguageList',

  mixins:[
    locale
  ],

  computed: {

    preference: function (){
      return Object.assign({}, this.$store.getters['sessionData/currentUser']?.preference)
    },

    currentLanguage: function (){
      return this.languages.filter((language) => language.code === this.$i18n.locale)[0]
    }

  },

  data(){
    return {

      languages: this.$store.getters['sessionData/languages'],

      AUTH_APP_DOMAIN: process.env.VUE_APP_AUTH_APP,
      DEUKOUWAY_APP_DOMAIN: process.env.VUE_APP_DEUKOUWAY_APP,

    }
  },

  methods: {

    async changeTo(language){

      this.setLocale(language.code)

      if(this.$store.getters['sessionData/currentUser']) {

        this.$store.getters['sessionData/currentUser'].preference.language = language

        if (!await PreferenceService.update(this.preference, {language_id: language.id})) {

          this.processing = false

          return this.notifyError()

        }

      }

    },

    capitalize(s){

      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)

    }

  },

}

</script>