<template>

  <section class="section section-faq" id="faqs">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-lg-6">
          <div class="section-head">
            <h2 class="title">{{ $t('Frequently Asked Questions') }}</h2>
            <p>{{ $t('You have a question ? We have answers. If you have any other questions, do not hesitate to write to us') }}.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="accordion accordion-s1 card card-shadow border-0 round-xl" id="accordion">
            <div class="accordion-item">
              <a href="index.html#" class="accordion-head" data-bs-toggle="collapse" data-bs-target="#accordion-item-1">
                <h6 class="title">{{ $t('Is opening a Samapayor account free') }} ?</h6>
                <span class="accordion-icon"></span>
              </a>
              <div class="accordion-body collapse show" id="accordion-item-1" data-bs-parent="#accordion">
                <div class="accordion-inner">
                  <p>{{ $t('Yes, opening a Samapayor account is totally free. No registration fees will be charged to you') }}.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <a href="index.html#" class="accordion-head collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-item-2">
                <h6 class="title">{{ $t('What payment methods does Samapayor support') }} ?</h6>
                <span class="accordion-icon"></span>
              </a>
              <div class="accordion-body collapse" id="accordion-item-2" data-bs-parent="#accordion">
                <div class="accordion-inner">
                  <p>{{ $t('Samapayor offers you a wide choice of payment channels') }}: <strong>Orange money</strong>, <strong>Wave</strong></p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <a href="index.html#" class="accordion-head collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-item-3">
                <h6 class="title">{{ $t('What are the countries where Samnapayor is present') }} ?</h6>
                <span class="accordion-icon"></span>
              </a>
              <div class="accordion-body collapse" id="accordion-item-3" data-bs-parent="#accordion">
                <div class="accordion-inner">
                  <p>{{ $t('The different countries where Samapayor is present are') }}: <strong>{{ $t('Senegal') }}</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

export default {

  name: 'Faq'

}

</script>