<template>

  <header class="header has-header-main-s1 bg-grad-a mb-5 mb-sm-6 mb-md-7" id="home">
    <div class="header-main header-main-s1 is-sticky is-transparent on-dark">
      <div class="container header-container">
        <div class="header-wrap">
          <div class="header-logo">
            <a href="#" class="logo-link">
              <img class="logo-light logo-img" src="@/assets/images/logo.svg" srcset="../../assets/images/logo2x.svg 2x" alt="logo" width="160" height="50">
              <img class="logo-dark logo-img" src="@/assets/images/logo-dark.svg" srcset="../../assets/images/logo-dark2x.svg 2x" alt="logo-dark" width="160" height="50">
            </a>
          </div>

          <div class="header-toggle">
            <button class="menu-toggler" data-target="mainNav">
              <em class="menu-on icon ni ni-menu"></em>
              <em class="menu-off icon ni ni-cross"></em>
            </button>
          </div>

          <nav class="header-menu" data-content="mainNav">
            <ul class="menu-list ms-lg-auto">
              <li class="menu-item"><a href="#home" class="menu-link nav-link active">{{ $t('Home') }}</a></li>
              <li class="menu-item"><a href="#reviews" class="menu-link nav-link">{{ $t('Why us') }} ?</a></li>
              <li class="menu-item"><a href="#faqs" class="menu-link nav-link">{{ $t('Faqs') }}</a></li>
              <li class="menu-item"><a href="#contact" class="menu-link nav-link">{{ $t('Contact') }}</a></li>

              <language-list-component v-if="$store.getters['sessionData/loaded']" />

            </ul>
            <ul v-if="$store.getters['sessionData/loaded']" class="menu-btns">
              <li v-if="$store.getters['sessionData/currentUser']"><a :href="`${DEUKOUWAY_APP_DOMAIN}`" target="_blank" class="btn btn-primary btn-lg">{{ $t('Dasboard') }}</a></li>
              <li v-else><a :href="`${AUTH_APP_DOMAIN}/login`" target="_blank" class="btn btn-primary btn-lg">{{ $t('Login') }}</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="header-content my-auto py-5 is-dark">
      <div class="container"><div class="row justify-content-center text-center">
        <div class="col-lg-7 col-md-10">
          <div class="header-caption">
            <h1 class="header-title">{{ $t('Increase your income with our solutions') }}</h1>
            <div class="header-text">
              <p>{{ $t('Thanks to innovative solutions that perfectly meet the needs of the sectors of activity, we support you in developing your business') }}.</p>
            </div>
            <ul v-if="!$store.getters['sessionData/currentUser']" class="header-action btns-inline">
              <li><a :href="`${AUTH_APP_DOMAIN}/register`" target="_blank" class="btn btn-primary btn-lg"><span>{{ $t('Getting started') }}</span></a></li>
              <li><a :href="`${AUTH_APP_DOMAIN}/login`" target="_blank" class="btn btn-danger btn-lg"><span>{{ $t('View Demo') }}</span></a></li>
            </ul>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="header-image mb-n5 mb-sm-n6 mb-md-n7">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-10">
            <div class="card overflow-hidden p-2 bg-light">
              <img src="@/assets/images/header/gfx-c.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

</template>

<script>

import {
  locale
} from '@/mixins'

import PreferenceService from '@/services/Preference'
import LanguageListComponent from "@/components/partials/LanguageList";

export default {

  name: 'Header',

  components: {
    LanguageListComponent
  },

  mixins:[
    locale
  ],

  computed: {

    preference: function (){
      return Object.assign({}, this.$store.getters['sessionData/currentUser']?.preference)
    },

    currentLanguage: function (){
      return this.languages.filter((language) => language.code === this.$i18n.locale)[0]
    }

  },

  data(){
    return {

      languages: this.$store.getters['sessionData/languages'],

      AUTH_APP_DOMAIN: process.env.VUE_APP_AUTH_APP,
      DEUKOUWAY_APP_DOMAIN: process.env.VUE_APP_DEUKOUWAY_APP,

    }
  },

  methods: {

    async changeTo(language){

      this.setLocale(language.code)

      if(this.$store.getters['sessionData/currentUser']) {

        this.$store.getters['sessionData/currentUser'].preference.language = language

        if (!await PreferenceService.update(this.preference, {language_id: language.id})) {

          this.processing = false

          return this.notifyError()

        }

      }

    },

    capitalize(s){

      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)

    }

  },

}

</script>