export default {

    namespaced: true,

    state: {
        currentUser: {},
        languages:   [],
        loading:     false,
        loaded:      false
    },

    getters: {

        currentUser (state) {
            return state.currentUser
        },

        languages (state) {
            return state.languages
        },

        loading (state) {
            return state.loading
        },

        loaded (state) {
            return state.loaded
        }

    },

    mutations: {

        setCurrentUser (state, value) {
            state.currentUser = value
        },

        setLanguages (state, value) {
            state.languages = value
        },

        setLoading (state, value) {
            state.loading = value
        },

        setLoaded (state, value) {
            state.loaded = value
        }

    }

}
