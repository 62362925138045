import Vue   from 'vue'
import App   from './App.vue'
import Vuex  from 'vuex'
import i18n          from './configs/i18n'
import VueCookies    from 'vue-cookies'
import store from './store'
import VueTelInput   from 'vue-tel-input'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueCookies)
Vue.use(VueTelInput)

new Vue({
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
