import axios  from 'axios'

const axiosApiInstance = axios.create()

axiosApiInstance.interceptors.request.use(
    async config => {

        config.withCredentials = true

        config.baseURL = process.env.VUE_APP_AUTH_API_DOMAIN

        config.headers = {
            'Accept':       'application/json',
            'Content-Type': 'application/json'
        }

        return config;

    }, error => {

        return Promise.reject(error)

    })

axiosApiInstance.interceptors.response.use(
    response => {

        return response

    }, async function (error) {

        return Promise.reject(error)

    })

export default axiosApiInstance
