<template>

  <section class="section secton-cta bg-lighter" id="contact">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-10">
          <div class="row align-items-center g-0">
            <div class="col-md-7">
              <div class="card card-shadow round-xl bg-indigo is-dark pb-4 pb-md-0">
                <div class="card-inner card-inner-xl">
                  <div class="text-block">
                    <h3 class="title">{{ $t('Contacts') }}</h3>
                    <ul class="list list-nostyle fs-16px">
                      <li>{{ $t('Online 24/7 support') }}</li>
                      <li><em class="ni ni-emails"></em> <strong>contact@samapayor.com</strong></li>
                      <li><em class="ni ni-call"></em> <strong>(+221) 76 392 79 26</strong> - <strong>(+221) 77 873 74 78</strong></li>
                      <li><em class="ni ni-location"></em> <strong>Ouest Foire, Cité ICS - Dakar - {{ $t('Senegal') }}</strong></li>
                      <li class="note text-warning">
                        <a :href="`${HELP_CENTER_APP}/contact`" target="_blank" class="note text-warning">
                          <em class="ni ni-comments"></em> {{ $t('I have a suggestion') }}
                        </a>
                      </li>
                    </ul>
                    <ul class="btns-inline">
                      <li><a :href="`${HELP_CENTER_APP}/contact`" target="_blank" class="btn btn-xl btn-primary">{{ $t('Contact us') }}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="card card-shadow round-xl ms-lg-n7 ms-md-n5 mx-4 me-md-0 mt-md-0 mt-n4">
                <div class="card-inner card-inner-lg">
                  <div v-if="form.submitted" class="text-center">
                    <em class="icon ni ni-check-circle-fill text-success" style="font-size: 10rem;"></em>
                    <h4>{{ $t('Question sent') }}</h4>
                  </div>
                  <div v-else class="form-block">
                    <div class="section-head section-head-sm">
                      <h4 class="title">{{ $t('Do you have any other question') }} ?</h4>
                    </div>

                    <ValidationObserver tag="div" v-slot="{ invalid, handleSubmit }" class="nk-kycfm">

                      <form @submit.prevent="handleSubmit(submit)" action="#" class="form-submit">
                        <div class="row g-4">
                          <div class="col-6">
                            <ValidationProvider tag="div" name="firstName" rules="required|min:2|max:255" v-slot="{ errors }" class="form-group">
                              <label class="form-label" for="firstName">{{ $t('First Name') }}</label>
                              <div class="form-control-wrap">
                                <input type="text" v-model="form.data.firstName" class="form-control form-control-lg" id="firstName" :placeholder="$t('Enter your first name')">
                              </div>
                              <span v-if="errors" class="invalid">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="col-6">
                            <ValidationProvider tag="div" name="lastName" rules="required|min:2|max:255" v-slot="{ errors }" class="form-group">
                              <label class="form-label" for="name">{{ $t('Last Name') }}</label>
                              <div class="form-control-wrap">
                                <input type="text" v-model="form.data.lastName" class="form-control form-control-lg" id="name" :placeholder="$t('Enter your last name')">
                              </div>
                              <span v-if="errors" class="invalid">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="col-12">
                            <ValidationProvider tag="div" name="email" rules="email" v-slot="{ errors }" class="form-group">
                              <label class="form-label" for="email">{{ $t('Email') }}</label>
                              <div class="form-control-wrap">
                                <input type="text" v-model="form.data.email" class="form-control form-control-lg" id="email" :placeholder="$t('Enter your email')">
                              </div>
                              <span v-if="errors" class="invalid">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="col-12">
                            <ValidationProvider tag="div" name="phoneNumber" :rules="`required|phoneNumber:${isValidPhoneNumber}`" v-slot="{ errors }" class="form-group">
                              <label class="form-label" for="email">{{ $t('Phone Number') }}</label>
                              <div class="form-control-wrap">
                                <vue-tel-input v-model="phoneNumber" @input="validatePhoneNumber" v-bind="bindProps" class="" style="height: calc(2.625rem + 2px);" :placeholder="$t('Enter your phone number')"></vue-tel-input>
                              </div>
                              <span v-if="errors" class="invalid">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="col-12">
                            <ValidationProvider tag="div" name="message" rules="required" v-slot="{ errors }" class="form-group">
                              <label class="form-label" for="question">{{ $t('Your Question') }}</label>
                              <div class="form-control-wrap">
                                <textarea v-model="form.data.message" class="form-control no-resize" id="question" :placeholder="$t('Your Question')"></textarea>
                              </div>
                              <span v-if="errors" class="invalid">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="col-12">
                            <submit-button-component text="Send" :disabled="invalid" :processing="form.processing" processing-text="Being processed..." button-class="btn btn-lg btn-primary"/>
                          </div>
                        </div>
                      </form>

                    </ValidationObserver>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

import {
  vueTelInput,
  notification,
  objectKeysConversion
} from '@/mixins'

import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'

import ContactService        from '@/services/Contact'
import SubmitButtonComponent from '@/components/commons/submit-button'

import '@/configs/veeValidate'

export default {

  name: 'Contact',

  components: {
    ValidationObserver,
    ValidationProvider,
    SubmitButtonComponent
  },

  mixins: [
    vueTelInput,
    notification,
    objectKeysConversion
  ],

  data(){
    return {

      HELP_CENTER_APP: process.env.VUE_APP_HELP_CENTER_APP,

      form: {
        data: {
          tag: 'help',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          phoneIndicative: '',
          phoneCountryCode: '',
          phoneCountry: '',
          country: 'Sénégal',
          subject: 'Question',
          message: ''
        },
        processing: false,
        submitted: false,
        errors: {}
      }

    }
  },

  methods: {

    async submit(){

      this.form.processing = true

      await ContactService.store(this.keysToSnack(this.form.data))

      this.form.processing = false

      this.form.submitted = true

      setTimeout(() => this.form.submitted = false, 5000)

    },

    validatePhoneNumber: function (input, { number, isValid, country }) {

      this.form.data.phoneCountry     = country.name
      this.form.data.phoneCountryCode = country.iso2
      this.form.data.phoneIndicative  = country.dialCode
      this.form.data.phoneNumber      = number.significant ?? input.replaceAll(' ', '')
      this.isPhoneNumberValid         = isValid && ! isNaN(number.input.replaceAll(' ', ''))

    },

  }

}

</script>

<style>

.vue-tel-input{
  border: 1px solid #dbdfea !important;
  padding: 0 !important;
}

.vue-tel-input:focus-within{
  border-color: #6576ff !important;
  box-shadow: 0 0 0 3px rgb(101 118 255 / 10%) !important;
}

.vti__input{
  color: #3c4d62;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b6c6e3 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b6c6e3 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #b6c6e3 !important;
}

.vti__dropdown {
  outline: none !important;
}

.vti__dropdown-list{
  z-index: 10 !important;
  width: 300% !important;
}

.vti__dropdown-list.below {
  top: 42px !important;
}

.vti__selection .vti__country-code{
  color: #3c4d62 !important;
}

.invalid{
  color: #e85347;
  font-size: 11px;
  font-style: italic;
}

</style>