<template>

  <section class="section section-facts bg-grad-a" id="facts">
    <div class="container">
      <div class="row g-gs align-items-center">
        <div class="col-lg-5">
          <div class="text-block is-dark pe-xl-5">
            <h2 class="title">{{ $t('About Samapayor') }}</h2>
            <p class="lead">{{ $t('With an ambition to expand more and more') }}. <br /> <strong>{{ $t('Everywhere and for everyone') }}</strong></p>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="row text-center g-gs">
            <div class="col-sm-4 col-6">
              <div class="card card-full round-xl">
                <div class="card-inner card-inner-md">
                  <div class="h1 fw-bold text-purple">1</div>
                  <div class="h6 text-base">{{ $t('Country of presence') }}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-6">
              <div class="card card-full round-xl">
                <div class="card-inner card-inner-md">
                  <div class="h1 fw-bold text-success">1</div>
                  <div class="h6 text-base">{{ $t('Product') }}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card card-full round-xl">
                <div class="card-inner card-inner-md">
                  <div class="h1 fw-bold text-pink">2</div>
                  <div class="h6 text-base">{{ $t('Payments channels') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nk-ovm shape-b shape-cover"></div>
  </section>

</template>

<script>

export default {

  name: 'Fact'

}

</script>