<template>

  <section class="section section-tesimonial bg-lighter" id="reviews">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-sm-9 col-md-7 col-10">
          <div class="section-head">
            <h2 class="title">{{ $t('Why us') }} ?</h2>
          </div>
        </div>
      </div>
      <div class="row gx-gs gy-5">
        <div class="col-lg-4 col-md-6">
          <div class="review">
            <div class="review-content card card-shadow round-xl">
              <div class="card-inner card-inner-lg">

                <div class="review-text">
                  <p>{{ $t('A team available 24/7 to assist you in the use of our solutions') }}.</p>
                </div>
              </div>
            </div>
            <div class="review-brand fw-bolder fs-22px text-body">{{ $t('Close relationship') }}</div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="review">
            <div class="review-content card card-shadow round-xl">
              <div class="card-inner card-inner-lg">

                <div class="review-text">
                  <p>{{ $t('No need to travel, Samapar will collect / debourser your payments for you') }}.</p>
                </div>
              </div>
            </div>
            <div class="review-brand fw-bolder fs-22px text-body">{{ $t('Time saving') }}</div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="review">
            <div class="review-content card card-shadow round-xl">
              <div class="card-inner card-inner-lg">

                <div class="review-text">
                  <p>{{ $t('We listen to you in order to offer you payment solutions adapted to the reality of your business') }}.</p>
                </div>
              </div>
            </div>
            <div class="review-brand fw-bolder fs-22px text-body">{{ $t('Adaptable solutions') }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

export default {

  name: 'Review'

}

</script>