<template>

  <div id="app">

    <div class="nk-app-root">
      <div class="nk-main ">

        <header-component />

        <area-component />

        <service-component />

        <review-component />

        <feature-component />

        <feature1-component />

        <fact-component />

        <faq-component />

        <contact-component />

        <footer-component />

      </div>
    </div>

    <toolbar-component />

    <app-panel-component />

  </div>

</template>

<script>

import {
  locale
} from '@/mixins'

  import HeaderComponent from '@/components/partials/Header'
  import ServiceComponent from '@/components/partials/Service'
  import ReviewComponent from '@/components/partials/Review'
  import FeatureComponent from '@/components/partials/Feature'
  import Feature1Component from '@/components/partials/Feature1'
  import FactComponent from '@/components/partials/Fact'
  import FaqComponent from '@/components/partials/Faq'
  import ContactComponent from '@/components/partials/Contact'
  import FooterComponent from '@/components/partials/Footer'
  import ToolbarComponent from '@/components/partials/Toolbar'
  import AppPanelComponent from '@/components/partials/AppPanel'
  import AreaComponent from '@/components/partials/Area'
  import SessionDataService from '@/services/SessionData'

  export default {

    name: 'App',

    components: {
      HeaderComponent,
      ServiceComponent,
      ReviewComponent,
      FeatureComponent,
      Feature1Component,
      FactComponent,
      FaqComponent,
      ContactComponent,
      FooterComponent,
      ToolbarComponent,
      AppPanelComponent,
      AreaComponent,
    },

    mixins:[
      locale
    ],

    data(){
      return {

        AUTH_APP_DOMAIN: process.env.VUE_APP_AUTH_APP

      }
    },

    async mounted() {

      await SessionDataService.loadAppData()

      if (this.$store.getters['sessionData/currentUser'])
        this.setLocale(this.$store.getters['sessionData/currentUser']?.preference?.language?.code)
      else
        this.setLocale('fr')

    }

  }

</script>

<style>

  .logo-img{
    max-width: 160px;
    max-height: 100px;
  }

</style>